import type { ComponentType } from "react"
import { useRef, useEffect } from "react"
import { motion, useSpring } from "framer-motion"

export function withScrollEffect(
    Component,
    movementAmount = -80 // Set the height of your navigation here
): ComponentType {
    return (props) => {
        const y = useSpring(0, { stiffness: 120, damping: 30 })
        const lastScrollY = useRef(0)

        useEffect(() => {
            const handleScroll = () => {
                const scrollDirection =
                    window.scrollY > lastScrollY.current ? "down" : "up"
                lastScrollY.current = window.scrollY
                if (scrollDirection === "down") {
                    y.set(movementAmount)
                } else {
                    y.set(0)
                }
            }

            window.addEventListener("scroll", handleScroll)

            return () => {
                window.removeEventListener("scroll", handleScroll)
            }
        }, [])

        return <Component {...props} as={motion.div} style={{ y }} />
    }
}
